import React, { useState, useEffect, useRef } from 'react';
import {
  Form, Button, Modal, Nav, Tabs, Tab, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { TimePicker } from 'smart-webcomponents-react/timepicker';
import { useParams, useHistory } from 'react-router-dom';
import moment from '../../functions/moment';
import 'smart-webcomponents-react/source/styles/smart.default.css';
import toastMessage from '../../functions/toastMessage';
import { userInstance } from '../../config/axios';
import Loader from '../loader/loader';

const VideoViewPopUp = () => {
  const videoNameObj = {
    overview: 'Overview',
    calendar: 'Calendar',
    calculator: 'Calculator',
    // 'using-calculator': 'Using Calculator',
    'how-to-do-a-water-test': 'How to do a Water Test',
    'green-pool-treatment': 'Green Pool Treatment',
    'black-spot-algae-treatment': 'Black-Spot Algae Treatment',
    'drain-or-part-drain-pool': 'Drain or Part-drain Pool',
    'pool-water-volume-via-salinity': 'Pool Water Volume via Salinity',
    'water-loss-calculator-': 'Water Loss Calculator ',
    'inverter-heater---heating-time-required-':
      'Inverter Heater - Heating Time Required ',
    'pool-chemistry-inter-relationship': 'Pool Chemistry Inter-relationship',
    'permissions-&-access-level': 'Permissions & Access Level',
    users: 'Users',
    // 'pool-set-up': 'Pool Set-up',
    'my-pool-size-&-chlorination': 'My Pool Size & Chlorination',
    'chemistry-target-levels': 'Chemistry Target Levels',
    'calculator-chemicals-to-display': 'Calculator Chemicals to Display',
    'chemical-costs': 'Chemical Costs',
    'chlorinator-model': 'Chlorinator Model',
    'drainage-times': 'Drainage Times',
    'mineral-pool-settings': 'Mineral Pool Settings',
    'chemicals-used-to-balance-water': 'Chemicals Used to Balance Water',
    'green-pool-calculations': 'Green Pool Calculations',
    'black-spot-algae-calculations': 'Black-Spot Algae Calculations',
    'drain,-pressure-wash,-chlorine-&-acid-wash-calculations':
      'Drain, Pressure Wash, Chlorine & Acid Wash Calculations',
    'sand-filter-media-replacement': 'Sand Filter Media Replacement',
    'calendar-settings-&-unit-of-measurement':
      'Calendar Settings & Unit of Measurement',
    'water-test-history': 'Water Test History',
    inventory: 'Inventory',
    // reminders: 'Reminders',
    // reports: 'Reports',
    // 'my-account': 'My Account',
    // 'my-profile': 'My Profile',
    // 'calculator-pro-billing': 'Calculator-Pro Billing',
    // 'help-centre': 'Help Centre',
    // 'chat-bot': 'Chat Bot',
    // 'getting-started': 'Getting Started',
    // 'video-tutorials': 'Video Tutorials',
  };

  const { id } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [videoSource, setVideoSource] = useState('');

  const handleViewVideo = async (categoryName) => {
    setLoading(true);
    try {
      const response = await userInstance().get(
        '/user/getVideosByCategory?', {
          params: {
            category: categoryName,
          },
        },
      );
      console.log('response', response);
      setLoading(false);
      if (response?.data?.videoDetails?.videoUrl) {
        setVideoSource(response?.data?.videoDetails?.videoUrl);
      } else {
        setVideoSource('');
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleViewVideo(videoNameObj[id]);
    }
  }, [id]);

  const handleBack = () => {
    localStorage.setItem('popup', 'true');
    history.goBack();
  };

  return (
    <>
      <div className="duration-picker time-picker-modal video_uploadImg">
        <div>
          <div className="time-keeper-popup duration-popup">
            <div className="back" onClick={handleBack}>
              <i className="las la-times" />
            </div>
            <div className="video_heading">
              <h6>Video Tutorial</h6>
              <h4>{videoNameObj[id] || ''}</h4>
            </div>
            {loading ? (
              <div className="video-loader">
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="video_upload viewUploadVedio">
                {videoSource !== '' ? (
                  <ReactPlayer width="100%" url={videoSource} controls />
                ) : (
                  <p>No Video Found</p>
                )}
              </div>
            )}
            <div className="template-btn view-btn single-btn">
              <button
                type="submit"
                className="btn btn-edit"
                onClick={handleBack}
              >
                {' '}
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoViewPopUp;
