/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { checkAuth } from '../../functions/index';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const UserRoute = ({ component: Component, ...rest }) => {
  const { userData } = useContext(AuthDataContext);
  const checkRoute = (routeName) => {
    if (routeName === '/a') {
      const dataIndex = userData?.permissions?.findIndex((x) => x.route === routeName);
      const newRoute = userData?.permissions[dataIndex + 1]?.route;
      return newRoute;
    }
    return routeName;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        let path = <Redirect to="/login" />;
        if (localStorage.getItem('s#@4f')) {
          // console.log(userData.accessLevel, props.location.pathname, userData.permissions);
          let isAuthorized = true;
          if (userData?.permissions.length > 0) {
            isAuthorized = checkAuth(userData.accessLevel, props.location.pathname, userData.permissions);
          }
          if (isAuthorized) {
            if (userData?.firstLogin === true) {
              path = <Redirect to="/update-password" />;
            } else {
              path = <Component {...props} {...rest} />;
            }
          } else if (userData?.permissions && userData?.permissions?.length > 0) {
            path = <Redirect to={checkRoute(userData?.permissions[0]?.route)} />;
          } else {
            path = <Redirect to="/401" />;
          }
        }
        return path;
      }}
    />
  );
};
// UserRoute.defaultProps = {
//   location: PropTypes.func,
// };
UserRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.func.isRequired,
};

export default UserRoute;
