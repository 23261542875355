import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Nav,
  NavDropdown,
  Button,
  Navbar,
  Form,
  Container,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
// import logo from '../../assets/home/logo-pool.png';
import { Helmet } from 'react-helmet';
import moment from '../../functions/moment';
import logowhite from '../../assets/home/pool-logo-white.png';
// import mobilelogo from '../../assets/home/mobile-logo.png';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { HeaderContext } from '../../contexts/HeaderContext';
import cancel from '../../assets/home/cross-icon.png';
// import openheader from '../../assets/home/hamburger-icon.png';
import openheader from '../../assets/home/hamburger-icon.png';
import openheaderblue from '../../assets/home/hamburger-icon-blue.png';
import mobilelogo from '../../assets/home/pool-white1.png';
import mobilelogoblue from '../../assets/home/pool-blue1.png';
import './header.css';
import { getCalendarPath, queryString } from '../../functions/index';
import './webfile.css';

const Header = ({
  chatBot,
  setVideoTutorial,
  handleActionName,
}) => {
  let history = useHistory();
  let calcPath = getCalendarPath();
  const { userData, setUserData } = useContext(AuthDataContext);
  const { showHeader, setShowHeader } = useContext(HeaderContext);
  const [isSticky, setIsSticky] = useState(false);
  const [desktopHeader, setDesktopHeader] = useState();
  const [subsciption, setSubsciption] = useState(false);
  const [disableHeader, setDisableHeader] = useState(false);

  const ref = React.createRef();
  const watchScroll = () => {
    if (window.scrollY > 60) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  const [headerState, setHeaderState] = useState([]);
  const [desktopHeaderState, setDesktopHeaderState] = useState([]);

  useEffect(() => {
    let remove = [];
    if (userData?.role !== 'professional-user') {
      remove = [
        'Subscription Management',
        'Chemical costs for predictive ordering',
      ];
    }
    const newArray = [];
    const secondArray = [];
    let userPermissionsData = userData.userPermissions;

    userPermissionsData.forEach((el, i, selfArr) => {
      if (
        ![
          'Water Test History',
          'Inventory',
          'Users',
          'Permissions & Access Level',
        ].includes(el.action)
        && el.class === 'main-menu'
        && i === selfArr.findIndex((t) => t._id === el._id)
      ) {
        newArray.push({
          ...el,
          child: userPermissionsData.filter(
            (elem, index, self) => elem.action !== '4-Pad & 6-Pad Test Strip Reader'
              && elem.category !== 'Calendar'
              && elem.category === el.category
              && (elem.route === '/subscriptions'
                || elem.route === '/calculator'
                || elem.action !== el.category)
              && index === self.findIndex((t) => t._id === elem._id),
          ), // for removing duplicay
        });
      }
      if (!remove.includes(el.action)) {
        secondArray.push(el);
      }
    });

    const dataForHeaderState = secondArray.filter(
      (value, index, self) => index === self.findIndex((t) => t._id === value._id),
    );
    setHeaderState([...dataForHeaderState]);
    setDesktopHeaderState([...newArray]);
  }, [userData?.role]);

  const handleAction = (view) => {
    setUserData({
      ...userData,
      showHeader: false,
    });
  };

  const getNavName = (action, view) => {
    let name = '';
    switch (action) {
      case 'Subscription Management':
        name = action;
        break;
      case 'Calculator':
        name = action;
        break;
      case 'Staff':
        name = action;
        break;
      case 'Dashboard':
        name = action;
        break;

      case 'My Pool Size & Chlorination':
        name = action;
        break;

      case 'Chemistry Target Levels':
        name = action;
        break;
      case 'Calculator Chemicals to Display':
        name = action;
        break;
      case 'Pool Chemistry Inter-Relationship':
        name = action;
        break;

      case 'Tax Applicable':
        name = action;
        break;
      case 'Chemical Costs':
        name = action;
        break;
      case 'Chlorinator Model':
        name = action;
        break;
      case 'Drainage Times':
        name = action;
        break;
      case 'Mineral Pool Settings':
        name = action;
        break;
      case 'Chemicals Used to Balance Water':
        name = action;
        break;
      case 'Green Pool Calculations':
        name = action;
        break;
      case 'Black-Spot Algae Calculations':
        name = action;
        break;
      case 'Drain, Pressure Wash, Chlorine & Acid Wash Calculations':
        name = action;
        break;
      case 'Sand Filter Media Replacement':
        name = action;
        break;
      case 'Second Visit Costs':
        name = action;
        break;
      case 'Calendar Settings & Unit Of Measurement':
        name = action;
        break;

      case 'Pool Set-up':
        name = action;
        break;
      case 'Calendar':
        name = action;
        break;
      case 'Inventory':
        name = action;
        break;
      case 'Settings':
        name = action;
        break;
      case 'Calendar settings & Unit of Measurement':
        name = action;
        break;
      case 'My Account':
        name = action;
        break;
      case 'My Profile':
        name = action;
        break;
      case 'Calculator-Pro Billing':
        name = action;
        break;
      case 'Help Centre':
        name = action;
        break;
      case 'Getting Started':
        name = action;
        break;
      case 'Video Tutorials':
        name = action;
        break;
      case 'Contact':
        name = action;
        break;
      case 'Permissions & Access Level':
        name = action;
        break;
      case 'Water Test History':
        name = action;
        break;
      case 'Users':
        name = action;
        break;
      //  uncomment this when using predictive-ordering section
      // case 'Chemical costs for predictive ordering':
      //   name = 'Chemical Costs for Predictive Ordering';
      //   break;
      case 'Chatbot':
        name = action;
        break;
      case 'Upload Videos':
        name = action;
        break;
      default:
        break;
    }

    return name;
  };

  useEffect(() => {
    if (window !== undefined && window.screen.width >= 768) {
      window.addEventListener('scroll', watchScroll);
    }
    return () => {
      window.removeEventListener('scroll', watchScroll);
    };
  }, []);

  const handleHeader = () => {
    setShowHeader(true);
  };
  const handleHideHeader = () => {
    setShowHeader(false);
  };
  const handleDesktopHeader = () => {
    setDesktopHeader(!desktopHeader);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const { pathname } = window.location;
    if (pathname === '/subscriptions') {
      setSubsciption(true);
    } else {
      setSubsciption(false);
    }

    if (pathname === '/invoice-details') {
      setDisableHeader(true);
    } else {
      setDisableHeader(false);
    }
  }, []);

  const handleLogin = () => {
    history.push('/login');
  };

  // const handleHome = () => {
  //   window.open('https://calendar-pro.com/');
  // };

  const handleChatBotModal = (actionName) => {
    if (actionName === 'Video Tutorials' || actionName === 'Upload Videos') {
      setVideoTutorial(true);
    }
    handleActionName(actionName);
    setShowHeader(false);
  };

  const checkPopUp = (routes) => ['/chatBot', '/billing', '/video-tutorials', '/upload-videos'].includes(routes);
  return (
    <>
      <Helmet>
        <body
          lang="en"
          className={`${showHeader || chatBot ? 'fixed-body-section' : ''} ${
            desktopHeader ? 'fixed-body-section' : ''
          } ${subsciption ? 'yellow-header' : ''} ${
            disableHeader ? 'disable-header' : ''
          }`}
        />
      </Helmet>
      <div
        className={`header mbl-header ${isSticky ? ' sticky' : ''} `}
        ref={ref}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="mobile-view">
                <a href="https://poolwater-pro.com" className="navbar-brand">
                  {/* <img src={mobilelogo} alt="logo" className="logo-dark" /> */}
                  {/* <img src={logowhite} alt="logo-white" className="logo-light" /> */}
                  {subsciption ? (
                    <img
                      src={mobilelogoblue}
                      alt="logo"
                      className="logo-dark"
                    />
                  ) : (
                    <img src={mobilelogo} alt="logo" className="logo-dark" />
                  )}
                </a>

                <div
                  className={`cross-icon ${showHeader ? 'show-header' : ''}`}
                >
                  {subsciption ? (
                    <img
                      src={openheaderblue}
                      alt="open"
                      onClick={handleHeader}
                      role="presentation"
                      className="openheader g"
                    />
                  ) : (
                    <img
                      src={openheader}
                      alt="open"
                      onClick={handleHeader}
                      role="presentation"
                      className="openheader g"
                    />
                  )}
                </div>
              </div>
              <div className="header-top d-none d-xl-block">
                {userData.auth ? (
                  <ul className="menu-profile-list">
                    <li className=" menu-profile-list d-flex align-items-center">
                      <span>Welcome : </span>
                      <h6>{userData?.email}</h6>
                    </li>
                    {/* <li className="menu-profile-item">
                    <Link to="/profile" className="nav-link"> My Profile </Link>
                  </li> */}
                    <li className="menu-profile-item">
                      <Button
                        type="submit"
                        className="btn btn-danger"
                        onClick={handleLogout}
                      >
                        {' '}
                        Logout
                        {' '}
                      </Button>
                    </li>
                  </ul>
                ) : null}
              </div>
              {showHeader && (
                <div className="custom-header mob-header">
                  <div className="custom-mob-section">
                    <div className="cross-icon">
                      <img
                        src={cancel}
                        alt="close"
                        onClick={handleHideHeader}
                        role="presentation"
                      />
                    </div>
                    <a
                      href="https://poolwater-pro.com"
                      className="navbar-brand"
                    >
                      <img src={mobilelogo} alt="logo" className="logo-dark" />
                      <img
                        src={logowhite}
                        alt="logo-white"
                        className="logo-light"
                      />
                    </a>
                    <div className="header-link">
                      {userData?.auth ? (
                        <Nav className="ml-auto">
                          {/* <Nav.Item>
                      <Link to="/dashboard" className="nav-link">Dashboard</Link>
                    </Nav.Item> */}
                          {headerState.map((permission) => (getNavName(permission.action) ? (
                            <>
                              {permission?.route
                                && permission?.route !== '/a' ? (
                                  <>
                                    {checkPopUp(permission?.route) ? (
                                      <>
                                        {userData?.role !== 'subscriber'
                                        && permission.route
                                          === '/billing' ? null : (
                                            <Nav.Item
                                              key={permission.route}
                                              className={`${permission.class}`}
                                            >
                                              <span
                                                onClick={() => handleChatBotModal(
                                                  permission.action,
                                                )}
                                                className="nav-link"
                                              >
                                                {getNavName(permission.action)
                                              === 'Calculator-Pro Billing'
                                                  ? 'PoolWater-Pro Billing'
                                                  : getNavName(permission.action)}
                                              </span>
                                            </Nav.Item>
                                          )}
                                      </>
                                    ) : (
                                      <Nav.Item
                                        key={permission.route}
                                        className={`${permission.class} ${
                                          permission.route
                                          === '/predictiveOrdering'
                                            ? 'subscription-link'
                                            : ''
                                        } ${
                                          permission.route === '/subscriptions'
                                            ? 'subscription-link'
                                            : ''
                                        }`}
                                        onClick={() => handleAction(permission.view)}
                                      >
                                        <Link
                                          to={
                                            permission.route === '/calendar'
                                              ? calcPath
                                              : permission?.view
                                                ? `${permission?.route}?view=${permission?.view}`
                                                : permission?.route
                                          }
                                          className="nav-link"
                                        >
                                          {getNavName(permission.action)}
                                        </Link>
                                      </Nav.Item>
                                    )}
                                  </>
                                ) : (
                                  <Nav.Item
                                    key={permission.route}
                                    className={`${permission.class} ${
                                      permission.route === '/predictiveOrdering'
                                        ? 'subscription-link'
                                        : ''
                                    } ${
                                      permission.route === '/subscriptions'
                                        ? 'subscription-link'
                                        : ''
                                    }`}
                                  >
                                    <Link
                                      to="/"
                                      className="nav-link disable-link"
                                    >
                                      {getNavName(permission.action)}
                                    </Link>
                                  </Nav.Item>
                                )}
                            </>
                          ) : null))}

                          <Nav.Item
                            className="d-block d-xl-none mt-2 logout-btn"
                            onClick={() => setUserData({ ...userData, showHeader: false })}
                          >
                            <Button
                              type="submit"
                              className="btn btn-danger"
                              onClick={handleLogout}
                            >
                              {' '}
                              Logout
                              {' '}
                            </Button>
                          </Nav.Item>
                        </Nav>
                      ) : !userData.auth ? (
                        <Nav className="ml-auto mt-2">
                          <Nav.Item className="submenu-item nav-item">
                            <Link to="/" className="nav-link">
                              Home
                            </Link>
                          </Nav.Item>
                          <Nav.Item className="submenu-item nav-item">
                            <Link to="/contact" className="nav-link">
                              Contact
                            </Link>
                          </Nav.Item>
                          <Nav.Item
                            className="d-block d-xl-none mt-2 logout-btn"
                            onClick={() => setUserData({ ...userData, showHeader: false })}
                          >
                            <Button
                              type="submit"
                              className="btn btn-danger"
                              onClick={handleLogout}
                            >
                              {' '}
                              Login
                              {' '}
                            </Button>
                          </Nav.Item>
                        </Nav>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`header web-header ${isSticky ? ' sticky' : ''} `}
        ref={ref}
      >
        <Container className="header-relative">
          <Navbar collapseOnSelect expand="lg">
            <div className="web-header-layout">
              <Navbar.Brand href="https://poolwater-pro.com">
                <img src={mobilelogo} alt="logo" className="logo-dark" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                {localStorage.getItem('s#@4f') && (
                  <Nav className="ml-auto">
                    {desktopHeaderState?.length > 0
                      && desktopHeaderState.map((el) => (
                        <>
                          {el?.child?.length === 0 ? (
                            <>
                              {!['Logout'].includes(el?.action) ? (
                                <Link className="nav-link" to={el?.route}>
                                  {el?.action}
                                </Link>
                              ) : null}
                            </>
                          ) : !['Logout'].includes(el?.action) ? (
                            <>
                              <NavDropdown title={el?.action}>
                                {el.child.map((ele) => (
                                  <>
                                    {ele?.route.includes('/')
                                      && ele.route !== '/a' && (
                                        <>
                                          {ele?.route === '/chatBot'
                                          || ele?.route === '/video-tutorials'
                                          || ele?.route === '/upload-videos' ? (
                                            <Nav.Item
                                              key={ele.route}
                                              className="chatdesk-icon"
                                            >
                                              <span
                                                onClick={() => handleChatBotModal(ele.action)}
                                                className="dropdown-item"
                                              >
                                                {getNavName(ele.action)}
                                              </span>
                                            </Nav.Item>
                                            ) : (
                                              <div
                                                onClick={() => handleAction(ele.view)}
                                                className={`${ele.class}`}
                                              >
                                                <Link
                                                  to={
                                                  ele.view
                                                    ? `/${
                                                      ele.view
                                                        === 'unitofmeasurement'
                                                        ? 'calendarSettings'
                                                        : 'poolsetup'
                                                    }?view=${ele.view}`
                                                    : ele.route
                                                }
                                                  className="dropdown-item"
                                                  void
                                                >
                                                  {getNavName(ele.action)}
                                                </Link>
                                                {/* </NavDropdown.Item> */}
                                              </div>
                                            )}
                                        </>
                                    )}
                                  </>
                                ))}
                              </NavDropdown>
                            </>
                          ) : null}
                        </>
                      ))}
                  </Nav>
                )}
              </Navbar.Collapse>
            </div>
          </Navbar>
          {localStorage.getItem('s#@4f') ? (
            <div className="auth-login">
              <p>
                Welcome:
                {userData.email}
              </p>
              <Button onClick={handleLogout}>Logout</Button>
            </div>
          ) : (
            <div className="auth-login">
              <Button onClick={handleLogin}>Login</Button>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};
Header.propTypes = {
  setVideoTutorial: PropTypes.func.isRequired,
  handleActionName: PropTypes.string.isRequired,
  chatBot: PropTypes.bool.isRequired,
};
export default Header;
