import openSocket from 'socket.io-client';
import { server } from '../config/keys';

const socket = openSocket(server, {
  transports: ['websocket'],
  rejectUnauthorized: false,
  query: {
    usertoken: localStorage.getItem('s#@4f'),
    stafftoken: localStorage.getItem('f#@5e'),
  },
});

socket.on('connect', () => {
  const userid = localStorage.getItem('s#@4f');
  const isLogin = localStorage.getItem('f#@5e');
  const isLoginId = localStorage.getItem('loginStaff');
  // console.log('connect ===>', isLoginId);
  if (isLogin && userid) {
    socket.emit('join', isLoginId);
  }
});
socket.on('reconnect', () => {
  console.log('reconnect');
  const userid = localStorage.getItem('s#@4f');
  const isLogin = localStorage.getItem('f#@5e');
  const isLoginId = localStorage.getItem('loginStaff');
  if (isLogin && userid) {
    socket.emit('join', isLoginId);
  }
});
const GetNotification = (cb) => {
  socket.on('GetNotification', (data) => {
    cb(data);
  });
};

const getProgressbar = (cb) => {
  socket.on('progressBar', (data) => {
    cb(data);
  });
};

export { socket, GetNotification, getProgressbar };
