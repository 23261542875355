import moment from 'moment-timezone';

let globalTimeZone = '';

export const updateTimeZone = (timeZone) => {
  if (timeZone) globalTimeZone = timeZone;
};

export const getTimeZone = (timeZone, date) => moment(date).tz(timeZone).format('YYYY-MM-DD HH:mm:ss');
export const getTodayTime = (timeZone) => moment().tz(timeZone).format('YYYY-MM-DD HH:mm:ss');
export const getTodayTimeZone = (timeZone) => moment().tz(timeZone).format('YYYY-MM-DDTHH:mm:ssZ');

export const convertDateTime = (date) => moment.parseZone(date);

export const udpateTime = (date, time, timeobj, addDays) => {
  let splitTime = time.split(':');
  const start = moment.parseZone(date);
  if (addDays) start.add(addDays, 'days');
  start.hour(splitTime[0]);
  start.minute(splitTime[1]);
  start.second(0);
  if (timeobj) return start;
  return start.format('YYYY-MM-DDTHH:mm:ssZ');
};

export const updateEndTime = (date, duration, timeobj) => {
  const end = date.minute(date.minutes() + parseFloat(duration));
  if (timeobj) return end;
  return end.format('YYYY-MM-DDTHH:mm:ssZ');
};
export const updateStartTime = (date, duration) => date.minute(date.minutes() + parseFloat(duration));
export const addTimeZone = (timeZone, date, time) => {
  const splitDate = date.split('T')[0];
  // Combine date, time, and time zone
  const dateTimeWithTimeZone = moment.tz(`${splitDate} ${time}:00`, timeZone);
  return dateTimeWithTimeZone;
};

export const displayTime = (timeZone, startTime, timeDisplayFormat) => {
  const time = timeDisplayFormat ? moment(startTime).tz(timeZone).format(timeDisplayFormat) : moment(startTime).tz(timeZone).format('HH:mm');
  return time;
};

export const getTimeZone2 = (timeZone, date) => moment(date).tz(timeZone).format('YYYY-MM-DDTHH:mm:ssZ');

export const mutateAppointmentServices = (appointmentArr, timeZone, dbTimeZone) => {
  if (dbTimeZone === timeZone) return appointmentArr;
  const updatedAppointment = appointmentArr?.map((elem) => {
    elem.date = convertDateTime(elem.date).format('YYYY-MM-DD');
    elem.service = elem.service.map((el) => {
      el.start = getTimeZone2(timeZone, el.start);
      el.end = getTimeZone2(timeZone, el.end);
      el.startTime = displayTime(timeZone, el.start);
      el.endTime = displayTime(timeZone, el.end);
      return el;
    });
    return elem;
  });
  return updatedAppointment;
};
