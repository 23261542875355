import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from '../../functions/moment';
import SquareCheckout from './squareCheckout';
import toastMessage from '../../functions/toastMessage';
import Loader from '../loader/loader';

const PaymentDetails = ({
  setDurationPicker,
  clientData,
  handleToken,
  returnPopUp,
  handleAction,
  smallLoader,
  paymentLoader,
}) => {
  console.log('this is the client data==>>>>>>', smallLoader);
  const timepickerRef = useRef(null);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal') {
            setDurationPicker(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const getDesiredEndDate = () => {
    const { duration } = clientData;
    let futureDate;
    if (duration === 12) {
      futureDate = moment(moment()).add(1, 'year').format('D MMM YYYY');
    } else if (duration === 24) {
      futureDate = moment(moment()).add(2, 'year').format('D MMM YYYY');
    } else if (duration === 36) {
      futureDate = moment(moment()).add(3, 'year').format('D MMM YYYY');
    } else {
      futureDate = moment(moment()).add(10, 'days').format('D MMM YYYY');
    }
    return futureDate;
  };
  return (
    <div
      className="duration-picker time-picker-modal payment-details-popup"
    >
      <div>
        <div className="time-keeper-popup duration-popup" ref={wrapperRef}>
          <div className="back-popup crossBtn" onClick={() => handleAction(returnPopUp)}>
            <i className="las la-times" />
          </div>
          <div className="billing-details-section">
            <h3 className="section-title">Billing Summary</h3>
            <div className="billl-content">
              <div className="upper-section">
                <p className="bill-label">
                  Subscription Duration:
                  <span>
                    {clientData.duration}
                    {' '}
                    months
                  </span>
                  <span>
                    {' '}
                    {`(${moment(moment()).format('D MMM YYYY')}`}
                    {' '}
                    -
                    {' '}
                    {`${getDesiredEndDate()})`}
                    {/* {moment(userInformation?.endsubscriptionDate).format('D MMM YYYY')} */}
                    {' '}
                  </span>
                </p>
                {/* <p className="bill-value">(9 May 2023 - 8 May 2025)</p> */}
              </div>
              <p className="bill-label">
                No. of Pools:
                <span>
                  {clientData.numberOfPools}
                  {' '}
                  Pools
                </span>
              </p>
              <p className="bill-label">
                No. of Users:
                <span>
                  {clientData.numberUsers}
                  {' '}
                  User

                </span>
              </p>
            </div>
            {clientData.extraCost && (
            <div className="total-cost">
              Cost to Pay:
              <span>
             
                {clientData.country === 'Australia' ? 'AU' : 'US'}
                $
                {clientData?.extraCost?.toFixed(2)}
              </span>
            </div>
            )}
            <div className="total-cost">
              Total Cost:
              <span>
                {clientData.country === 'Australia' ? 'AU' : 'US'}
                $ 
                {clientData?.totalCost?.toFixed(2)}
              </span>
            </div>
            <h3 className="section-title">Card Details</h3>
            <div className={smallLoader ? 'show-loader-payment' : 'hide-loader-payment'}>
              <div className="video-loader">
                <Spinner animation="border" />
              </div>

              <div className="payment-form">
                <SquareCheckout handleToken={handleToken} amountDisplay={clientData.extraCost ? clientData.extraCost : clientData.totalCost} paymentLoader={paymentLoader} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
PaymentDetails.propTypes = {
  setDurationPicker: PropTypes.func.isRequired,
  clientData: PropTypes.objectOf.isRequired,
  handleToken: PropTypes.func.isRequired,
  returnPopUp: PropTypes.string.isRequired,
  handleAction: PropTypes.string.isRequired,
  smallLoader: PropTypes.bool.isRequired,
  paymentLoader: PropTypes.func.isRequired,
};
export default PaymentDetails;
